import React from "react"
import { useStaticQuery, graphql, Link, } from "gatsby"
import SEO from "../../../../components/Layout/Seo"
import tw from "twin.macro"
import styled from "@emotion/styled"
import imageBgFotos from "../../../../images/fondo_precios.svg"
import { StaticImage } from "gatsby-plugin-image"
import { PlanDescription } from "../../../../components/plans/PlanDescription"
import { PlanPaymentMethods } from "../../../../components/plans/PlanPaymentMethods"
import { MoreInfo } from "../../../../components/Layout/MoreInfo"
import LayoutPage from "../../../../components/global/Layout"
import { IPageWithPlan } from "../../../../components/plans/types"




const Precios = ({ data }: IPageWithPlan) => {

  return (
    <div
      style={{
        backgroundImage: `url(${imageBgFotos})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        minHeight: "calc(80vh - 0px)",
      }}
    >
      <LayoutPage>
        <div
          style={{
          }}
          css={tw`w-full  flex-col lg:pt-150px  flex     mx-auto max-w-content px-8 justify-between `}
        >
          <div
            css={tw`my-auto  space-y-1 lg:space-y-6 mx-auto flex flex-col`}
          >

            <div
              css={tw`grid lg:grid-cols-2  mt-0 lg:my-auto  pt-0  lg:text-left text-center  `}
            >
              <div css={tw`mt-4 lg:mt-0 lg:block hidden w-full lg:w-300px 2xl:w-400px lg:ml-32    `}>
                <StaticImage
                  src="../../../../images/portales-CONTADOR-ICON.png"
                  width={1150}
                  quality={100}
                  layout="fullWidth"
                  placeholder="none"
                  className="mr-0 ml-auto"
                  formats={["auto", "webp", "avif"]}
                  alt="precios para usar autogestiones mayorista"
                />{" "}
              </div>
              <div css={tw`lg:pr-24 mt-0 lg:my-auto  `}>
                <h1
                  css={tw`text-3xl lg:text-4xl mt-10 lg:pt-0 pt-150px lg:mt-0 text-autogestiones-card   `}
                >
                  ¿Querés tener varias cuentas de <br />
                  <span className="text-gradient-secondary" css={tw`font-bold`}>
                    Autogestiones Cloud?
                  </span>
                </h1>
                <p
                  css={tw`text-gray-300 text-base  lg:text-base  font-normal w-full    pt-4`}
                >
                  Tenemos la solución para tener todos a la vez, en tu panel
                </p>

                <p css={tw`text-sm mt-4 text-gray-400`}>
                  *Sin tarjeta requerida, ni contrato.
                </p>
              </div>

            </div>

          </div>

        </div>
        <PlanDescription />
        <div
          css={tw`flex-col w-full flex   gap-8 mx-auto max-w-content px-4`}
        >
          <PlanPaymentMethods />
        </div>
        <div css={tw`w-full mt-8 mx-auto flex  max-w-content px-4`}>
          <div css={tw`mx-auto flex`}>
            <a target="_blank" href="https://calendly.com/autogestiones/asesoramiento">
              <MoreInfo  >
                <span tw="  font-bold">Agendá ahora</span>
              </MoreInfo>
            </a>
          </div>
        </div>
      </LayoutPage>
    </div>
  )
}
export default Precios

export const Head = () =>
  <SEO
    description="Vendé más con planes grandes para alojar multiples clientes en un mismo lugar"
    lang="es-AR"
    title="Planes para Revender" />





 